@font-face {
  font-family: 'Sahar';
  font-weight: normal;
  src: local('Sahar'), url(./assets/fonts/Sahar-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Sahar';
  font-weight: bold;
  src: local('Sahar'), url(./assets/fonts/Sahar-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Sahar';
  font-weight: 100;
  src: local('Sahar'), url(./assets/fonts/Sahar-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  font-weight: bold;
  src: local('Sahar'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

html {
  background-color: #040b10 !important;
  overflow: hidden !important;
}

body {
  font-size: 0.9em !important;
}

.main-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  font-family: "Sahar", "Arial", SansSerif !important;
}

h1, h2, h3, h4, h5 {
  color: #fff
}

h1 {
  letter-spacing: 6px;
}

h2 {
  text-align: center;
  color: black;
  font-size: 99px !important;
  font-weight: lighter !important;
}

h3 {
  text-align: left;
  color: black;
  font-size: 4rem !important;
  font-weight: lighter !important;
}

.default-background {
  background-color: #040b10;
  /* background-color: #040b10; */
}

.nav-logo {
  height: 37px;
  max-height: 1000px !important;
}

.fully-loaded {
  animation-play-state: paused;
}

.loaded-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.loading-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  padding-left: 40%;
  padding-right: 40%;
}

.mask {
  overflow: hidden;
}

.text-animation-up {
  position: relative;
  transform: translateY(120%);
  transition: all 1.25s cubic-bezier(0.25, 0.1, 0.1, 1);
  -webkit-transition: all 1.25s cubic-bezier(0.25, 0.1, 0.1, 1);
  will-change: transform;
}

.show {
  transform: translateY(0);
}

.fade-out-fast {
  animation: 0.5s cubic-bezier(0.25, 0.1, 0.1, 1) 1s normal forwards 1 fadeout;
}

.fade-in-fast {
  animation: 0.5s cubic-bezier(0.25, 0.1, 0.1, 1) 0s normal forwards 1 fadein;
}

.fade-in {
  animation: 1s cubic-bezier(0.25, 0.1, 0.1, 1) 0.7s normal forwards 1 fadein;
}

.fade-in-delay-1 {
  animation: 1s cubic-bezier(0.25, 0.1, 0.1, 1) 1.5s normal forwards 1 fadein;
}

.fade-in-delay-2 {
  animation: 1s cubic-bezier(0.25, 0.1, 0.1, 1) 2s normal forwards 1 fadein;
}

.fade-in-delay-3 {
  animation: 1s cubic-bezier(0.25, 0.1, 0.1, 1) 2.5s normal forwards 1 fadein;
}

.opacity-0 {
  opacity: 0;
}

.preview-line {
  margin: auto;
  display: flex;
  height: 1px;
  width: 0px;
  background: #00ff9d;
  animation: 10s cubic-bezier(0.25, 0.1, 0.1, 1) 0s normal forwards 1 growwide;
}

.intro-container {
  z-index: 200;
}

#start {
  display: flex;
  flex-direction: column;
}

.intro-button {
  margin-top: 40px;
}

.une-logo{
width:15%;
}

.intro-p {
  
  padding: 40px 0px 0px 0px;
  font-family: "Sahar", "Arial", sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

#globe-video {
  position:fixed;
  width: 100%;
  height: 160vh !important;
  top: 50%;
  right: -21%;
  z-index: 100;
  transform:rotateY(180deg) translateY(-50%)
}

#main-info-container{
pointer-events:none;
}

button{
  pointer-events:all;
}

.control-panel {
  pointer-events: none;
}

.dropdown{
  pointer-events:all;
  margin-top: 0.9rem;
}

.column.is-half.intro-container {
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.property-selector {
  pointer-events: none;
}

.columns.is-multiline.is-mobile {
  pointer-events: none;
}

.column.is-full.pb-1.pt-1 {
  pointer-events: none;
}

.intro-button {
  width: 170px;
  height: 40px;
  padding: 14px 54.5px 10px;
  background: none;
  border: none;
  letter-spacing: 2.4px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}

.intro-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: #00ff9d;
  z-index: -30;
}

.intro-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: white;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1,
  -45deg) translate3d(0, -3em, 0);
  transform: rotate3d(0, 0, 1,
  -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.intro-button:hover::before {
  opacity: 1;
  background-color: white;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.echo-index-12 {
  margin-left: 10px;
  border: 1px solid white;
  border-radius: 40%;
  padding: 9px 13px;
  padding-top: 13px;
  font-size: 0.8rem;
  display: inline-block;
  font-weight: bold;
  letter-spacing: 1px !important;
}

.navbar {
  background-color: transparent !important;
  background-image: none !important;
  padding: 20px 50px;
}

.navbar button {
  cursor: pointer;
}

.mobile-echo-button {
  display: none !important;
}

.navbar-center {
  letter-spacing: 3px;
  flex-grow: 1;
  justify-content: center !important;
}

.navbar-item {
  padding: 0 !important;
  color: #fff !important;
}
.navbar-item > .nav-logo-mobile { display:none; }

.navbar-item:hover {
  color: #00ff9d !important;
}

.navbar-item:hover .echo-index-12 {
  border-color: #00ff9d;
}

.boxy-outline {
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 32px !important;
  border-color: #979797 !important;
}

.navbar-burger {
  border-radius: 40% !important;
  color: #979797 !important;
  cursor: pointer;
}

.navbar-burger:hover {
  border-color: #00ff9d !important;
  color: #00ff9d !important;
}

.boxy-outline:hover {
  border-color: #00ff9d !important;
}

.padded-about {
  padding: 40px 32px !important;
  font-size: x-small;
  font-weight: bold;
  margin-top: 20px;
}

.expand-button {
  position: absolute;
 

  width: 100%;
  height: 100%;
  border-radius: 999px;
}

.center-expand-button {
  position: absolute;
  margin-left: -50px;
  margin-top: 10vh;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 999px;
}

.expanded-button {
  background: white;
  left: -120vw;
  top: -80vh;
  width: 200vw;
  height: 200vw;
  border-radius: 999px;
  transition: all 0.75s ease-in;
}
.close-button {
  background: white;
  left: 20vw;
  top: -10vh;
  width: 0vw;
  height: 0vw;
  border-radius: 999px;
  transition: all 0.45s ease-in;
}

.center-expanded-button {
  background: white;
  left: -85vw;
  top: -200px;
  width: 180vw;
  height: 180vw;
  transition: all 0.70s ease-in;
}
.center-close-button {
  background: white;
  left: 10vw;
  top: -10vh;
  width: 0vw;
  height: 0vw;
  border-radius: 999px;
  transition: all 0.45s ease-in;
}

.controls-container {
  position: absolute;
  top: 150px;
  padding: 0 50px;
}

.control-panel {
  margin-top: 1.8rem;
}

.map-container {
  position: absolute;
  z-index: -10000;
  height: 100vh;
  width: 100%;
  top: 0;
  overflow: hidden;
  pointer-events: auto;
}

.map-mobile-overlay {
  position: absolute;
  z-index: -5000;
  height: 100vh;
  width: 100%;
  top: 0;
  overflow: hidden;
  background: rgba(0, 255, 157, 0.1);
  pointer-events: none;
}

.dropdown-arrow {
  border-right: solid 1px white;
  border-bottom: solid 1px white;
  height: 10px !important;
  width: 10px !important;
  margin-left: auto !important;
  margin-right: 4px !important;
  transform: rotate(45deg) translateY(-5px);
}

.dropdown-arrow:hover {
  border-color: #00ff9d;
}

.dropdown-control {
  background: rgba(0, 0, 0, 0.3) !important;
  color: white !important;
  font-size: 0.9rem !important;
  border-radius: 0 !important;
  border-color: #979797 !important;
  padding: 25px 15px !important;
  text-align: left;
  justify-content: left !important;
  width: 260px !important;
}

.dropdown-control:hover {
  /*background: none !important;*/
  border-color: #00ff9d !important;
  border-radius: 0 !important;
}

.dropdown-menu {
  padding-top: 0 !important;
}

.dropdown-content {
  border-radius: 0 !important;
  padding: 0 !important;
}

.green-border {
  border-color: #00ff9d !important;
}

.dropdown-item {
  padding: 13px 13px !important;
  padding-right: 47px !important;
  font-size: 0.9rem !important;
  color: black;
  border: none;
  cursor: pointer;
  background: white;
}

.dropdown-item:hover {
  background: #00ff9d !important;
}

.dropdown-item.is-active {
  background: #00ff9d !important;
}

.property-selector {
  margin-top: 3rem;
}

.property-selector button {
  background: none !important;
  border: none;
  border-left: solid 1px #979797;
  padding: 15px;
  padding-right: 40px;
  color: white;
  cursor: pointer;
  position: relative;
}

/*.property-selector button:hover {*/
/*  border-left: solid 1px #00ff9d;*/
/*}*/

.property-selector button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(110deg, grey 80%, transparent 20%) !important;
  z-index: -166;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: width 0.3s, opacity 0.3s, background-color 0.3s;
  transition: width 0.3s, opacity 0.3s, background-color 0.3s;
}

.property-selector button:hover::after {
  width: 100%;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.property-selector button.is-active::after {
  /*opacity: 0;*/
  z-index: -200;
  width: 100%;
}

.property-selector button.is-active {
  border-left: solid 1px #00ff9d;
  width: 100px;
  padding: 15px;
  padding-right: 40px;
  color: black;
  font-weight: bold;
  font-size: 1.3rem;
}

.property-selector button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(110deg, #00ff9d 80%, transparent 20%) !important;
  z-index: -1;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: width 0.3s, opacity 0.3s, background-color 0.3s;
  transition: width 0.3s, opacity 0.3s, background-color 0.3s;
}

.property-selector button.is-active::before {
  opacity: 1;
  width: 100%;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.bottom-container {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
  padding-left: 50px;
}

.investor-logo svg {
  position: absolute;
  bottom: 30px;
}

.value-legend {
  color: white;
  font-size: 0.8rem;
  text-align: right;
  position: absolute;
  width: 20%;
  bottom: 30px;
  right: 50px;
  /*background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.45), rgba(0, 0, 0, 0.4) 100%);*/
  border-top-left-radius: 250px;
  /*box-shadow: -20px 0px 100px 100px rgba(0,0,0,0.43);*/
}

.padded-close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 40px 32px !important;
  font-size: x-small;
  background: none;
  cursor: pointer;
}

.padded-close-button:hover {
  color: #00ff9d;
}

.modal-card {
  padding: 0 150px;
  overflow-y: scroll !important;
  background: #fff;
}

.modal-index {
  padding-right: 0;
}

.modal-index-title {
  text-align: left;
  margin-top: 70px;
  line-height: 0.9;
}

.modal-index-subtitle {
  text-align: left;
  font-size: 6rem;
  line-height: 1;
}
.modal-index-subtitlew {
  text-align: left;
  font-size: 9rem!important;
  line-height: 1;
  padding-top: 100px;
  margin-bottom: -120px;
}

.animated.animate__fadeInUp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.is-half {
  font-size: 1em;
}
.pr-4 {
  font-size: 1em;
  margin-top: 50px;
}
.echo-container {
  padding-top: 100px;
}

.device-indicators {
  width: 100%;
  margin-top: -100px !important;
}

.device-indicators .indicator {
  font-weight: bold;
  font-size: 0.6rem;
  width: 25%;
  height: 0.1vw;
  border-left: #00ff9d solid 2px;
  padding-top: 150px;
  padding-bottom: 0px;
  z-index: -100;
}

.map-legend-button {
  animation-name: map-legend-button;
  animation-duration: 3s;
  width: 140px;
  margin-top: 10px;
  border: none;
  background: none;
  color: white;
  text-align: right;
  cursor: pointer;
  
}
@keyframes map-legend-button {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

.map-legend-button:hover {
  color: #00ff9d;
}

.map-legend-exclamation {
  text-align: center;
  font-weight: bold;
  font-size: 0.7rem;
  border: solid 1px white;
  border-radius: 50%;
  width: 13%;
  display: inline-block;
  padding-top: 2%;
}

.map-legend-button:hover > .map-legend-exclamation {
  border-color: #00ff9d;
}

.map-value-desc-container {
  position: absolute;
  bottom: 20vh;
  right: 0;
  padding-right: 50px;
  padding-left: 150px;
  padding-top: 100px;
  width: 639px;
  transition: opacity 0.4s ease-in;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.7) 0, rgba(0,0,0,0.6), rgba(0, 0, 0, 0.45) 100%);
  border-top-left-radius: 250px;
  box-shadow: -20px 0px 200px 200px rgba(0,0,0,0.5);
  opacity: 0;
}

.map-value-desc-container-show {
  opacity: 1;
}

.map-value-desc-container-hide {
  right: -1000px;
}

.map-value-desc-container .delete {
  padding: 15px !important;
  max-height: 60px !important;
  max-width: 60px !important;
}

.map-value-desc-title {
  margin-left: -120px;
  font-weight: bold;
  font-size: 2em;
  padding-bottom: 10px;
}

.map-value-desc-title:before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  top: 80px;
  left: -95px;
  border-bottom: 1px solid #00ff9d;
}

.p-relative {
  position: relative;
}

.toyota-yaris-background {
  width: 100%;
  height: 100%;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.map-value-desc-text {
  color: white !important;
  margin-top: -20px;
  line-height: 1.8;
  padding: 0px 20px;
}

.close-button-wrapper {
  position: relative;
  float: right;
  display: block;
  margin-top: -60px;
  color: rgba(255, 255, 255, 1);
  font-size: 1.3rem;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  width: 55px;
  height: 55px;
  border: solid 1px #979797;
  border-radius: 20px;
  background: none;
}

.close-button-wrapper:hover {
  color: #00ff9d;
  border-color: #00ff9d;
}

.collapsable-toggle {
  background: none;
  border: none;
  right: 0;
  position: absolute;
  font-weight: lighter;
  font-size: 60px;
  cursor: pointer;
}

.collapsable-content {
  max-height: 0;
  overflow: hidden;
  transition: all 1.55s ease-out;
}
.collapsable-content-img img{
  padding: 15px;
  max-width: 100px;
  height: 100px;
}

#about-title {
  font-size: 6rem!important;
  text-align: left;
}

.collapsable-content.expanded {
  transition: all 1s ease-in;
  max-height: 3000px;
  padding: 20px 0px;
}

.profile-picture {
  width: 80%;
}

.scale-container {
  border: solid rgba(0,0,0,0.2) 2px;
  padding: 30px
}

@media screen and (min-width: 1080px) and (max-width: 1400px) {
  .toyota-yaris-background {
    background-position: center;
  }
  #about-title {
    font-size: 6.4rem!important;
    
  }
  .scale-container {
    
    margin-top: -40px!important;
  }
  .pr-4 {
    font-size: 1.1rem;
    margin-top:  0px;
  }
  .echo-container {
    padding-top: 80px;
  }

  .modal-index-subtitle {
    font-size: 5rem !important;
    margin-bottom: 40px;
  }
  .modal-card {
    padding: 0 0px 0 70px;
  }
  .modal-index-subtitlew {
    font-size: 7rem !important;
  }
  
  h2 {
   
    font-size: 3rem !important;
  }
  .center-expanded-button {
    
    left: -120vw;
    width: 250vw;
    height: 250vw;
  
  }
  .center-expand-button {
    
    margin-left: 20px;
   
  }
  .expanded-button {
  
    left: -140vw;
   
  
  }
  .expand-button {
   
   
    margin-left: -200px;
    
  }
}

.modal.modal-fx-fadeIn .modal-content {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; 
}
.modal.modal-fx-fadeIn.is-active .modal-content {
  opacity: 1;
  transition: all 0.3s;
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .mobile-echo-button {
    position: relative;
    display: inline !important;
    margin-left: auto;
    margin-right: 20px;
  }

  .echo-container {
    padding-top: 0;
  }

  h2 {
   
    font-size: 3rem !important;
  }

  .modal-index-title {
    font-size: 4rem !important;
  }

  .modal-index-subtitle {
    font-size: 4rem !important;
  }

  .toyota-yaris-background {
    background-position: center;
  }
}

/* animations */
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fillBar {
  0% {
    width: 0;
  }
  10% {
    width: 10px;
  }
  20% {
    width: 20px;
  }
  30% {
    width: 30px;
  }
  40% {
    width: 40px;
  }
  50% {
    width: 50px;
  }
  60% {
    width: 60px;
  }
  70% {
    width: 70px;
  }
  80% {
    width: 80px;
  }
  90% {
    width: 90px;
  }
  100% {
    width: 200px;
  }
}

@keyframes growwide {
  0% {
    width: 0;
  }
  100% {
    width: 150px;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: rgb(0, 0, 0);
  color: #000;
  border: 1px solid #000;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #000000;
  border-bottom: 1px solid #0e0d0d;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__day-name{
  color: #7f8fa4;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-family: OpenSans-Bold !important;
  text-transform: uppercase;
}
.react-datepicker__day,
.react-datepicker__time-name {
  background: #FFFFFF;
  color: #2c3135;
  display: inline-block;
  width: 2.5rem;
  /*line-height: 1.7rem;*/
  text-align: center;
  margin: 0.166rem ;
  width: 58px;
  height: 24px;
  margin: 8px 5px 8px 0;
  padding: 7px 17px
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  /*border-radius: 0.3rem;*/
  background-color: #00ff9d;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  /*border-radius: 0.3rem;*/
  background-color: #00ff9d;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #00ff9d;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  /*border-radius: 0.3rem;*/
  background-color: #00ff9d;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #00ff9d;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: #00ff9d;
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  /*color: #ccc;*/
  background-color: transparent;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
.w-50per{
  width: 49%;
  padding: 15px;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
@media screen and (min-width: 0) and (max-width: 768px) {
  .navbar {
    padding: 20px 20px 0;
  }

  .react-datepicker__day, .react-datepicker__time-name {
    color: #2c3135;
    height: 44px;
    padding: 18px 17px;
    width: 2.5rem;
    width: 44px;

}

  .navbar-burger {
    margin-left: 0 !important;
  }

  .nav-logo.nav-logo-desktop {
    display: none;
  }
  .nav-logo-mobile {
    display: block !important; width: 85px; height: 19px;
  }

  #about-title{
    font-size: 4rem!important;
  }

  .echo-container {
    padding-top: 20px;
  }

  .modal-index-subtitle {
    font-size: 5rem !important;
  }
  .modal-index-subtitlew {
    font-size: 4rem !important;
    margin-bottom: -60px;
  }

  .mobile-echo-button {
    position: relative;
    display: inline !important;
    margin-left: auto;
    margin-right: 20px;
  }

.intro-container{
  padding-top:24vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

  .default-background{

    background-color:transparent;
  }


.container.default-background{

  z-index: index 999;
}

  .controls-container {
    padding-right: 0;
    padding-left: 20px;
    width: 90px;
    top: 80px !important;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.45), rgba(0, 0, 0, 0.4) 100%);
    border-top-left-radius: 250px;
    box-shadow: -20px 0px 100px 100px rgba(0,0,0,0.43);
  }

  .control-panel {
    margin-top: 1rem;
  }

  .bottom-container {
    padding-left: 20px;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.45), rgba(0, 0, 0, 0.4) 100%);
    border-top-left-radius: 250px;
    box-shadow: -20px 0px 100px 100px rgba(0,0,0,0.43);
  }

  .value-legend {
    width: 60%;
    bottom: 10px;
    right: 20px;
  }

  .map-value-desc-container {
    display: flex;
    align-items: flex-end;
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    border-radius: unset;
    background: rgba(0,0,0,0.93);
    z-index: 1000;
    overflow: scroll;
    padding: 200px 50px 20px 30px;
  }

  .map-value-desc-container-hide {
    right: -1000px;
  }

  .map-value-desc-title {
    margin-left: 0;
    margin-bottom: 50px;
    padding-bottom: 10px;
  }

  .map-value-desc-title:before {
    left: 24px;
  }

  .map-value-desc-text {
    padding: 0px;
  }

  .modal-full-screen {
    position: absolute !important;
  }

  .modal-nav {
    padding: 0 10px;
  }

  .modal-card {
    padding: 0px;
  }

  .modal-index-title {
    margin-top: 30px;
  }

  h2 {
    text-align: left;
    font-size: 1.8rem !important;
  }

  .mobile-section {
    padding: 15px !important;
  }

  .profile-picture {
    width: 100%;
  }

  .scale-container {
    border: unset;
    padding: 30px
  }
  .mask{
    justify-content: center;
  }
  #start{
    align-items: center;
  }
  .une-logo{
    width: 25%;
  }
  .intro-p{
    padding: 0px 20px;
    font-size: 18px;
    text-align: center!important;
    margin-top: 20px;
  }
  #globe-video{
    right: 0%;
    top:19%;
    width: 100%;
    position: absolute;
    z-index:0;
  }

  .w-50per {
    padding: 0;
    width: 100%;
}

  .unea-logo-wrapper{
    text-align: center;
  }
  .intro-container{
    margin: auto;
  }
  #main-info-container{
    margin: auto;
  }
  .intro-button{
    margin-top: 30px;
  }
  .pd-x{
    padding: 0px 20px;
  }
  iframe{    
    width: 100%;
    height: 300px;
  }
}

@media screen and (min-height: 0) and (max-height: 750px) {
  
  .controls-container {
    top: 120px;
  }

  .property-selector {
    margin-top: 1rem !important;
  }

  .property-selector button {
    font-size: 0.7rem;
    padding: 10px;
  }

  .property-selector button.is-active {
    width: 100px;
    /*padding: 10px;*/
    padding-left: 15px;
    padding-right: 30px;
    font-size: 0.7rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
.react-datepicker__day--outside-month{
  background-color: #edf2f50d;
  pointer-events: none!important;
}

.react-datepicker__day {
  border-radius: 3px;
}
.tooltip {
  position: absolute;
  margin: 8px;  
  padding-block: 10px;
  padding-left: 20px;
  padding-right: 10px;
  /* background: rgba(0, 0, 0, 0.8); */
  color: #fff;
  max-width: 300px;
  font-size: 1em;
  z-index: 9;
  pointer-events: none;
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 0% 100%);
}